import useSWR, { type SWRConfiguration } from 'swr'
import {
  type UserSubscritionStatus,
  type Event,
  type User,
  type LaravelNotification,
  type TaskType
} from '@/types'
import { destroy, get, onceGet, paginationGet, patch, post } from './fetcher'
import { type AxiosError } from 'axios'
import useSWRCursorPaginate from './useSWRCursorPaginate'
import { type SWRInfiniteConfiguration } from 'swr/infinite'

export const useMe = (options?: SWRConfiguration) => {
  return useSWR<User, AxiosError>('/api/v2/users/me', get, options)
}

export const useReservedEvents = (options?: SWRConfiguration) => {
  return useSWR<Event[], AxiosError>(
    '/api/v2/users/me/reserved_events',
    get,
    options
  )
}

export const useUsers = (options?: SWRInfiniteConfiguration) => {
  return useSWRCursorPaginate<User>('/api/v2/users', options)
}

/**
 * @param id - userが任意に設定できるmy_id（ユーザーに自動で割り当てられたIDではない）
 * @param options - SWRConfiguration
 * @returns - SWRResponse
 */
export const useUser = (id: string, options?: SWRConfiguration) => {
  return useSWR<User, AxiosError>(`/api/v2/users/${id}`, get, options)
}

export const useMeVipStatus = (options?: SWRConfiguration) => {
  return useSWR<UserSubscritionStatus, AxiosError>(
    '/api/v2/users/me/subscriptions/vip',
    get,
    options
  )
}

export const useMeNotifications = (options?: SWRConfiguration) => {
  return useSWRCursorPaginate<LaravelNotification>(
    '/api/v2/users/me/notifications',
    options
  )
}

export const useMeTasks = (options?: SWRConfiguration) => {
  return useSWRCursorPaginate<LaravelNotification & { type: TaskType }>(
    '/api/v2/users/me/tasks',
    options
  )
}

export const useMeNotification = (id: string, options?: SWRConfiguration) => {
  // Notificationのみ、dataにラップされずに返却されるため、paginationGetを使用
  return useSWR<LaravelNotification, AxiosError>(
    `/api/v2/notifications/${id}`,
    paginationGet,
    options
  )
}

type UserFetchParams = {
  my_id: string
  email: string
  current_password: string
  password: string
  password_confirmation: string
  name: string
  birthday?: string | null
  gendar: string
  live: string
  nationality: string
  learn_language: string
  // learn_language_level: number
  ja_language_level: number
  en_language_level: number
  want_speed: number
  bio?: string | null | undefined
  bio_url?: string | null | undefined
  hobbies?: Array<string | undefined> | undefined
}

export const updateMe = async (params: Partial<UserFetchParams>) => {
  const data = await patch<User>(`/api/v2/users/me`, params)
  return data
}

export const updateMeIcon = async (file: File) => {
  const formData = new FormData()
  formData.append('upload_file', file)
  const data = await post<Event>(`/api/v2/users/me/icon`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return data
}

type SimpleMessageProps = {
  to: string
  message: string
}
export const sendSimpleMessage = async ({ to, message }: SimpleMessageProps) => {
  return await post(`/api/v2/users/${to}/simple_message`, { to, message })
}

export const removeMe = async () => {
  await destroy('/api/v2/users/me')
}

export const useUsersByFilter = (
  filter: string | undefined,
  options?: SWRInfiniteConfiguration
) => {
  return useSWR<User[], AxiosError>(
    filter ? `/api/v2/users?filter=${filter}` : null,
    get,
    options
  )
}

export const setBanUser = async (id: string, ban: boolean) => {
  return await patch<User>(`/api/v2/users/${id}/banuser?ban=${ban}`)
}

export const setAdminUser = async (id: string, admin: boolean) => {
  return await patch<User>(`/api/v2/users/${id}/adminuser?admin=${admin}`)
}

export const setCountBorderNow = async (id: string) => {
  return await patch<User>(
    `/api/v2/users/${id}/absence_events/count_border/now`
  )
}

export const deleteNotification = async (id: string) => {
  return await destroy(`/api/v2/notifications/${id}`)
}

export const publishedSubscriptionUrl = async (id: string) => {
  return await onceGet<{ url: string }>(`/api/v2/users/${id}/subscription-url`)
}
